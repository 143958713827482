// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

//Font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #ffff !important;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #ffff !important;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--open
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon {
	color: #ffff !important;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #02151e !important;
	border-color: #02151e !important;
	color: #fff !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	color: #fff !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action > i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.btn-table-action-small > i {
	width: 35px !important;
	font-size: 12px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
	padding: 0 !important;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #ff2500 !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	background-color: #02151e !important;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead > tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: 60px;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.MuiFormLabel-root {
	color: #667f8a !important;
	overflow: hidden !important;
	width: 80%;
	text-overflow: ellipsis !important;
	white-space: nowrap;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 1px solid #878691 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #878691 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #67666e !important;
}

.btn-success .fa-edit {
	color: #fff !important;
}

.btn > i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.anexoBtnRemove {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.anexoBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.anexoContainer {
	margin-top: 15px;
	margin-bottom: 15px;
}

.kt-notification__item-icon > i {
	color: #02151e !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu > a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu > a:hover {
	text-decoration: none !important;
	// color: #212121 !important;
}

.kt-scrolltop {
	background: #02151e !important;
}

.btn {
	border-radius: 0px !important;
}

.btn-app {
	border-color: #ff2601 !important;
	background-color: #ff2601 !important;
	color: white !important;
	border-radius: 0px !important;
}

.btn-app:hover {
	border-color: #ff3f1f !important;
	background-color: #ff3f1f !important;
	color: white !important;
}

.btn-app-color {
	border-color: #02151e !important;
	background-color: white !important;
	color: #02151e !important;
}

.btn-app-color:hover {
	// border-color: #02151e !important;
	// background-color: #02151e !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.MuiInputBase-input {
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 12px !important;
	color: #032a3a !important;
}

.atalho-dashboard {
	background-color: #02151e !important;
	color: white !important;
	height: 200px !important;
	font-weight: 600 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.atalho-dashboard > i {
	font-size: 4.5rem !important;
	margin: 0px 0px 25px 0px !important;
}

.atalho-dashboard > h3 {
	font-size: 1.8rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard {
	margin: 50px 60px 50px 60px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no-content-dashboard > i {
	font-size: 5rem !important;
	margin: 0px 0px 40px 0px !important;
}

.no-content-dashboard > h3 {
	font-size: 2.3rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard > p {
	margin-top: 10px !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
}

.item-atendimentos-hoje:not(:last-child) {
	margin-bottom: 2.3rem !important;
}

.item-atendimentos-hoje > .kt-timeline-v2__item-text {
	font-size: 1.2rem !important;
	padding: 0.2rem 0 0 5rem !important;
}

.nav-item.nav-link {
	margin-bottom: 10px !important;
	color: #7c9ca8 !important;
	border-radius: 0 !important;
	height: 30px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
}

.nav-item.nav-link:hover:not(.active) {
	color: #999 !important;
	border: 1px solid transparent !important;
}

.nav-item.nav-link.active {
	background-color: #ffddda !important;
	height: 30px;
	border-radius: 15px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #f72400 !important;
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
}

.nav-item.nav-link:first-child {
	border-left: 1px solid transparent !important;
}

.nav-tabs {
	border-bottom: 1px solid #ddd !important;
}

.tab-pane {
	padding: 0px 10px 0px 10px !important;
}

#tabs-frete-tabpane-dados {
	background-color: #f2f2f2;
}

a.btn:hover {
	color: white !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #02151e !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: rgba(20, 125, 191, 0.5) !important;
}

.MuiSwitch-thumb {
	box-shadow: none !important;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.tableHeaderItem {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.tableItemOrange {
	border-radius: 0 !important;
	background-color: #ff2500 !important;

	&:hover {
		background-color: #ff2600a8 !important;
	}
}

.tableItemGreen {
	border-radius: 0 !important;
	background-color: #00e350 !important;

	&:hover {
		background-color: #00ff59a8 !important;
	}
}

.form-control:focus {
	border-color: #607d8b;
}

.btn.btn-label-brand {
	background-color: white !important;
	color: #02151e !important;
}

.btn.btn-label-brand:hover,
.btn.btn-label-brand:focus {
	// background-color: #02151e !important;
	color: white !important;
}

.empty-icon {
	margin-top: 50px;
	font-size: 4.2rem;
	color: #032a3a;
}

.empty-text {
	margin: 50px 25px 50px 25px;
	font-size: 1.5rem;
	color: #032a3a;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: #fff;
}

::-webkit-scrollbar-thumb {
	background: #ff2601;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
	background: #ff2701b4;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.swal2-popup {
	border-radius: 15px !important;
}

.btn-filtros {
	margin-right: 5px;
	background-color: #dadada !important;
	color: #003042 !important;
	border: none;

	&:hover {
		color: #003042 !important;
		background-color: #ddd2d2 !important;
	}
}

.rc-steps-item-finish .rc-steps-item-icon {
	border-color: #ff2601 !important;
}

.rc-steps-item-title::after {
	background-color: #ff2601 !important;
}

.app-page-title {
	margin: 42px 0px 5px 0px;
	font-weight: bold;
	font-size: 1.5rem;
	line-height: 21px;
	color: #003042;
}

.app-page-subtitle {
	font-size: 1rem;
	line-height: 22px;
	color: #7c949e;
}

.dashboard-title {
	margin-top: 30px;
	font-weight: 600;
	color: #004056;
	font-size: 12px;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border: none;
	display: flex !important;
	flex: 1;
	justify-content: space-between;
	background-color: #f2f2f2;
	border-bottom: 1px solid rgba(0, 64, 86, 0.5);
}

.bgGray {
	background-color: #dadada !important;

	&:hover {
		background-color: rgb(209, 209, 209) !important;
		color: #7a7a7a !important;
	}
}

.bgOrange {
	background-color: #ff2500 !important;

	&:hover {
		background-color: #f76248 !important;
		color: #fff !important;
	}
}

.card-frete {
	background-color: #ffffff;
	padding: 15px;
	border-radius: 10px;
	padding: 20px 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 260px;
	box-shadow: 2px 2px #d4d4d4;
}

.card-agendamento-frete {
	background-color: #ffffff;
	padding: 15px;
	border-radius: 10px;
	padding: 20px 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 280px;
	box-shadow: 2px 2px #d4d4d4;
}

.card-agendamento-frete-cancelado {
	background-color: #ffffff;
	padding: 15px;
	border-radius: 10px;
	padding: 20px 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 300px;
	max-height: auto;
	box-shadow: 2px 2px #d4d4d4;
}

.container-card {
	padding: 10px;
}

.text-lote {
	color: #032a3a;
	font-size: 18px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
}

.text-transportadora {
	font-size: 12px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
}

.card-agendamento-text-empresa {
	font-size: 12px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
}

.text-produto {
	font-size: 12px;
	padding: 0;
	margin: 0;
	display: inline;
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
}

.container-text-total-frete {
	display: flex;
	flex: 1;
	align-items: flex-end;
}

.container-text-motivo-recusa {
	display: flex;
	flex: 1;
}

.text-total-frete {
	font-size: 14px;
	padding: 0;
	margin: 0;
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
}

.text-total-frete-value {
	font-size: 14px;
	padding: 0;
	margin: 0;
	display: flex;
	flex: 1;
	align-items: flex-end;
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
}

.text-motivo-recusa {
	font-size: 14px;
	padding: 0;
	margin: 0;
	display: flex;
	flex: 1;
	align-items: flex-start;
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
}

.container-origem-destino {
	background-color: #f7f8f9;
	padding: 15px 10px;
	display: flex;
	flex-direction: row;
	margin: 15px 0px;
}

.container-rota {
	padding-top: 3px;
	padding-bottom: 4px;
	flex-direction: column;
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.dot-origem {
	background-color: #ff2601;
	height: 10px;
	width: 10px;
	border-radius: 5px;
}

.bar-rota {
	width: 2px;
	flex: 1;
	flex-direction: column;
	background-color: #ff2601;
	margin-top: 2px;
	margin-bottom: 2px;
}

.dot-destino {
	background-color: #ffffff;
	border: solid 2px #ff2601;
	height: 10px;
	width: 10px;
	border-radius: 5px;
}

.label-origem-destino {
	color: #032a3a;
	font-size: 12px;
	font-weight: 600;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
}

.value-origem-destino {
	color: #667f8a;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	padding: 0;
	margin: 0;
}

.container-space-between-column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 10px;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.text-bold {
	font-weight: 600 !important;
}

.btn-refresh {
	margin-right: 40px;
}

.btn-encerrar-recrutamento {
	padding: 15px 20px !important;
	background-color: rgb(73, 99, 109);
}

.btn-adicionar-transportadoras {
	padding: 15px 20px !important;
	background-color: rgb(73, 99, 109);
}

.btn-adicionar-anexo {
	padding: 15px 20px !important;
	background-color: #ff2601;
}

.btn-abrir-checklist {
	padding: 15px 20px !important;
	background-color: #ff2601;
	margin-left: 10px;
}

.text-btn-adicionar-anexo {
	color: #ffffff;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

.text-btn-encerrar-recrutamento {
	color: #ffffff;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	width: 300px;
}

.text-btn-adicionar-transportadoras {
	color: #ffffff;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

.text-btn-cadastrar-embarcador {
	color: #ffffff;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

.row-flex-end {
	display: flex;
	justify-content: flex-end;
}

.container-frete {
	padding: 40px 40px 20px 20px;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.text-cnh-cam {
	color: #032a3a;
	font-weight: 400;
	font-size: 12px;
	margin-bottom: 0px !important;
}

.text-nome-cam {
	margin-bottom: 0 !important;
}

.dados-caminhoneiro-files {
	margin-bottom: 1rem;
}

.edit-peso-modal-dados {
	margin-left: 10px;
}

.text-frete-lote {
	color: #032a3a;
	font-size: 18px;
	font-weight: bold;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
}

.text-agendamento-title {
	color: #032a3a;
	font-size: 17px;
	font-weight: bold;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
}

.text-frete-tipo-produto {
	color: #667f8a;
	font-size: 14px;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
}

.text-frete-data-criacao {
	color: #657d86;
	font-size: 12px;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
}

.text-frete-title-toneladas {
	color: #032a3a;
	font-size: 14px;
	font-weight: bold;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
}

.text-frete-toneladas {
	color: #657d86;
	font-size: 14px;
	padding: 0;
	margin: 0;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
}

.divider-sides {
	border-left: solid 2px #d1d1d1;
	border-right: solid 2px #d1d1d1;
}

.divider-right {
	border-right: solid 2px #d1d1d1;
}

.divider-left {
	border-left: solid 2px #d1d1d1;
}

.container-center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.nav-item {
	background-color: transparent !important;
	border: 0 !important;
}

#tabs-frete-tab-caminhoneiros {
	border: 0 !important;
}

#tabs-frete-tab-dados {
	border: 0 !important;
}

#tabs-frete-tab-anexos {
	border: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.container-tab {
	background-color: transparent !important;
	height: 100%;
}

.tab-content {
	background-color: #ffffff !important;
}

.container-filtros {
	background-color: #ffffff;
	margin-bottom: 30px;
	margin-left: 1px;
	margin-right: 1px;
}

th {
	background-color: transparent !important;
}
.status-chip {
	background-color: #ebeff1 !important;
	border: 0;
	height: 26px;
	border-radius: 13px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 9px;
	font-weight: 500;
	text-align: center;
	color: #7c9ca8 !important;
}

.label-info-frete {
	font-size: 14px;
	padding: 0;
	margin: 0;
	display: inline;
	color: #032a3a;
	font-weight: normal;
	font-family: "Roboto";
	font-style: normal;
}

.dropdown-item {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
}

.dropdown-menu {
	min-width: 10rem !important;
}

.chip-aprovado {
	background-color: #deece8 !important;
	color: #008a5e !important;
}

.chip-aprovado[disabled] {
	background-color: #deece8 !important;
	color: #008a5e !important;
}


.chip-reprovado {
	background-color: #ffe8e6 !important;
	color: #ff0724 !important;
}

.chip-reprovado[disabled] {
	background-color: #ffe8e6 !important;
	color: #ff0724 !important;
}

.chip-analise {
	background-color: #f9efe1 !important;
	color: #ffac40 !important;
}

.chip-analise[disabled] {
	background-color: #f9efe1 !important;
	color: #ffac40 !important;
}

.chip-andamento {
	background-color: #ddf1f7 !important;
	color: #00aeeb !important;
}

.chip-andamento[disabled] {
	background-color: #ddf1f7 !important;
	color: #00aeeb !important;
}

.text-info-frete {
	font-size: 14px;
	padding: 0;
	margin: 0;
	display: inline;
	color: #667f8a;
	font-weight: normal;
	font-family: "Roboto";
	font-style: normal;
}

.title-info-agendamento {
	font-size: 14px;
	padding-left: 0;
	margin: 0;
	display: inline;
	color: #667f8a;
	font-weight: normal;
	font-family: "Roboto";
	font-style: normal;
}

.text-info-agendamento {
	font-size: 14px;
	padding-left: 5px;
	margin: 0;
	display: inline;
	color: #667f8a;
	font-weight: normal;
	font-family: "Roboto";
	font-style: normal;
}

.display-block {
	display: block;
}

.tabs-frete-tab-anexos {
	background-color: transparent !important;
}

.container-btn-anexo {
	margin: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.card-frete-anexo {
	padding-top: 30px;
	padding-bottom: 10px;
	margin-top: 8px;
	background-color: #ffffff;
	border-radius: 10px 10px 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.card-frete-caminhoneiro-anexo {
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 8px;
	background-color: #ffffff;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#container-anexos {
	padding-top: 30px !important;
	background-color: #f2f2f2;
}

.title-anexo {
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 0;
}

#tabs-frete-tabpane-anexos {
	background-color: #f2f2f2;
}

#container-filter-anexo {
	background-color: white;
	height: 85px;
}

.btn-delete-anexo {
	background: #ff2d00;
	text-align: center;
	border-radius: 0 0 10px 10px;
}

.text-btn-delete-anexo {
	color: #fff;
	margin: 0;
	padding: 5px 0;
}

.avatar {
	height: 100px !important;
	width: 100px !important;
}

.avatar-icon {
	height: 80px !important;
	width: 80px !important;
}

.chip-perfil {
	color: white;
	margin-top: 10px;
}

.container-block-modal {
	background-color: #f2f2f2;
	height: 440px;
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.container-tab-anexos {
	background-color: #f2f2f2;
	display: flex;
	padding-top: 20px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
}

.container-tab-dados-peso {
	background-color: #f2f2f2;
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.container-tab-cadastro {
	background-color: #f2f2f2;
	padding: 20px 30px;
}

.btn-editar-frete {
	padding: 7px 30px;
	background-color: #7c9ca8;
}

.btn-cancelar-cadastro-embarcador {
	padding: 7px 30px;
	background-color: #ff2601;
}

.btn-cadastrar-embarcador {
	padding: 7px 30px;
	margin-left: 20px;
	background-color: #7c9ca8;
}

.container-add-embarcador {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.btn-add-embarcador {
	padding-bottom: 0px;
	background-color: #7c9ca8;
}

.text-btn-add-embarcador {
	color: #ffffff;
	margin: 0;
	padding-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

.text-resultado-cadastro-embarcador {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
}

.container-btn-editar {
	display: flex;
	justify-content: flex-end;
}

.btn-iniciar-recrutamento {
	flex: 1;
	padding: 15px 60px !important;
	background-color: #7c9ca8;
}

.text-btn-iniciar-recrutamento {
	color: #ffffff;
	padding: 0;
	margin: 0;
	font-size: 24px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
}

.container-btn-iniciar-recrutamento {
	display: flex;
	justify-content: center;
}

a {
	color: inherit !important;
	/* blue colors for links too */
	text-decoration: inherit !important;
	/* no underline */
}

.container-button-dashboard {
	display: flex;
	justify-content: space-between;
}

.btn-filter {
	border-color: #dadada !important;
	background-color: #dadada !important;
	color: #003042 !important;
	border-radius: 0px !important;
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
}

.container-checkbox {
	display: flex;
	flex-direction: row;
}

.kt-menu__link-icon.fab.fa-whatsapp {
	color: #646c9a !important;
}

.container-btn-filtrar {
	display: flex;
	justify-content: flex-end;
}

.container-btn-modal-new-embarcador {
	display: flex;
	justify-content: flex-end;
}

.container-item-popover {
	display: flex;
	align-items: center;
}

.text-popover {
	color: #032a3a;
	font-size: 14px;
	margin: 0;
	padding: 0;
	margin-left: 10px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
}

.color-cancelar {
	color: #ff2500;
}

.text-disponivel {
	color: #032a3a;
	font-size: 20px;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 50px;
}

.text-titulo-anexos {
	color: #032a3a;
	font-size: 20px;
	text-align: center;
}

.container-dados-caminhoneiro {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.color-nao-perigoso {
	color: #008a5a;
}

.color-perigoso {
	color: #ff2500;
}

.ui-datatable * {
	border: 0px !important;
}

.cursor-pointer {
	cursor: pointer;
}

#tabs-frete-tabpane-caminhoneiros {
	background-color: #f2f2f2;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
	background-color: #f7f8f9;
}

.icon-options {
	color: #032a3a;
}

.container-accordion {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-clip: border-box;
}

.container-checkbox-new-frete {
	display: flex;
	flex-direction: column;
}

.img-logo-dados {
	width: 76px;
}

.container-whatsapp {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 19px;
	cursor: pointer;
}

.container-whatsapp-icon {
	background-color: #008979;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.whatsapp-icon {
	color: #ffffff;
}

.whatsapp-text {
	margin-left: 8px;
	margin-bottom: 0;
	padding-bottom: 0;
	color: #7c9ca8;
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
}

.recrutamento-encerrado {
	padding: 15px 20px !important;
	background-color: #7c9ca8;
}

.mt-24 {
	margin-top: 24px !important;
}

#tabs-frete-tabpane-detalhes {
	background-color: #f2f2f2;
	padding-top: 32px !important;
	padding-bottom: 22px !important;
}

.primary-color {
	color: #032a3a;
}

body .p-datatable .p-datatable-thead > tr > th {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center !important;
	color: #02151e;
}

body .p-datatable .p-datatable-tbody > tr > td {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	text-align: center;
	color: #032a3a;
	overflow-wrap: break-word;
}

body .p-datatable .p-datatable-tbody > tr > td > .dropdown {
	display: flex;
	justify-content: center;
}

body .p-paginator .p-paginator-current {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	vertical-align: center;
	margin-top: 3px !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	vertical-align: center;
	margin-top: 3px !important;
}

body .p-dropdown .p-dropdown-label {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	vertical-align: center;
	padding-right: 3em !important;
	margin-top: 3px !important;
}

.div-cancelar-frete {
	display: flex;
	margin-left: 8px;
}

.btn-cancelar-frete {
	flex: 1;
	background-color: #ff2500;
}

.custom-paginator {
	width: 1160px;
	margin: 0 auto;
  }

@media (max-width: 1367px) {
	.datatable-column-id {
		width: 45px;
	}

	.datatable-column-cpf-cnpj {
		width: 90px;
	}

	.datatable-column-placa {
		width: 80px;
	}
}

.container-limpar-filtros {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.text-limpar-filtros {
	padding-bottom: 0;
	margin-bottom: 0;
	color: #032a3a;
	font-family: "Roboto";
	font-size: 12px;
	font-weight: normal;
	cursor: pointer;
}

.icon-limpar-filtro {
	color: #032a3a;
}

.MuiCheckbox-colorPrimary.Mui-checked {
	color: #032a3a !important;
}

.button-ver-mapa,
.buttons-anexos {
	background-color: transparent;
	border: none;
	color: #343a40;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: #ff6200 !important;
	color: white !important;
}

.remover-file-ou-image {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
	background-color: #ff2601;
}

.remover-file-ou-image:hover {
	opacity: 0.9;
}

.text-remover-file-ou-image {
	color: #ffffff;
	font-style: normal;
}

.btn-ver-frete-cam-anexo:hover {
	opacity: 0.7;
}

.pointer {
	cursor: pointer;
}

.btn-danger {
	color: white !important;
}

.containerPhoto {
	position: fixed;
	max-width: 50%;
	width: 40vw;
	height: 100vh;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;

	.photo {
		height: 100vh;
		width: auto;
	}
}

.loginContainer {
	.formSide {
		padding-left: 25px;

		h1 {
			font-family: "Roboto";
			font-weight: 400;
			color: #02151e;
			font-size: 30px;
			margin-top: 48px;
		}

		img {
			margin-top: 10% !important;
		}

		.rememberForgot {
			margin: 10px 0;
			justify-content: space-between;
		}

		.checkbox span:not(:first-of-type) {
			font-family: "Poppins";
			font-size: 14px;
			line-height: 20px;
			color: #596165;
		}

		a {
			display: flex;
		}

		.forgot {
			font-family: "Poppins";
			font-size: 14px;
			line-height: 20px;
			color: #596165;
			font-weight: 700;
			align-self: center;
			margin-bottom: 0.5rem;
		}

		.buttonContainer {
			button {
				color: #fff;
				background-color: #ff2500 !important;
				border-color: #ff2500 !important;
				border-radius: 5px !important;
				height: 46px;
				font-size: 16px;
				padding-left: 2.5rem;
			}
		}

		.registerContainerBoxLogin {
			h2 {
				margin-top: 15px;
				font-family: "Poppins";
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				color: #02151e;
			}

			p {
				font-family: "Poppins";
				font-size: 14px;
				line-height: 24px;
				color: #02151e;
				max-width: 500px;
			}
		}

		.createAcc {
			p {
				font-family: "Poppins";
				font-size: 18px;
				line-height: 24px;
				color: #ff2500;
				cursor: pointer;
			}
		}
	}
}

.checkboxAgree {
	font-family: "Poppins";
	font-size: 14px;
	line-height: 20px;
	color: #596165;
}

.heightPdf {
	height: 900px;
}

.is-size-7.is-vcentered.has-text-centered.is-inline-flex.has-padding-top-5.button.is-black.is-marginless.has-margin-left-5.has-margin-right-5 {
	margin-left: 15px;
	margin-right: 15px;
}

button.column.is-3.buttons.are-small.has-addons.is-centered span i {
	display: flex;
}

.registerContainer {
	.formSide {
		margin-left: 50%;

		h1 {
			font-family: "Roboto";
			font-weight: 400;
			color: #02151e;
			font-size: 30px;
		}

		.arrowLeft {
			margin-top: 10% !important;
		}

		.selectProfile {
			width: 500px;
			font-family: "Poppins";
			font-size: 14px;
			line-height: 20px;
			color: #596165;
		}
	}

	.boxContainer {
		.box {
			background-color: #fff;
			border-radius: 10px;
			display: flex;
			align-items: center;
			padding: 30px 40px;
			box-shadow: 0 2px 15px 0 hsl(0deg 0% 72% / 50%);
			margin-top: 50px;
			cursor: pointer;
			max-width: 80%;
			height: 165px;

			p {
				width: 70%;
				font-family: "Poppins";
				font-size: 12px;
				line-height: 20px;
				color: #596165;
			}

			&:hover {
				transform: scale(1.05);
			}

			.imageContainer {
				padding: 20px;
				background-color: #f4f4f4;
				width: 64px;
				height: 62px;
				border-radius: 5px;
			}

			.phraseContainer {
				padding-left: 20px;

				h3 {
					font-family: "Poppins";
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					color: #02151e;
				}
			}
		}
	}

	.duvidas {
		font-family: "Poppins";
		font-size: 12px;
		line-height: 20px;
		color: #596165;
	}
}

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	background-size: cover !important;
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.btn-orange {
	height: 51.5px !important;
	background-color: #ff2500 !important;
	display: flex;
	align-self: center;
	justify-content: center;
	flex: 0;
	width: 150px;
	border: none;
	margin-top: 16px;
	margin-bottom: 8px;
}

.paginatorLeilao {
	margin-top: 20px;
	border: none !important;
	background-color: #f0f0f0 !important;
}

.paginatorLote {
	margin-top: 50px;
	border: none !important;
	background-color: 'transparent' !important;
}

.showLeilao {
	.borderLeilao {
		border-left: #f1f1f179 solid 1px;
	}

	h3 {
		color: #48465b;
	}

	h4 {
		color: #48465b;
	}
}

.OrcamentoShow {
	.containerOrcamento {
		font-family: "Poppins";
		font-weight: 600;
		font-size: 15px;
		line-height: 24px;
		color: #02151e;
	}

	.valorOrcamento {
		font-family: "Poppins";
		font-size: 14px;
		line-height: 20px;
		color: #596165;
	}

	.titulo {
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #02151e;
	}

	.borderLeilao {
		border-left: 1px solid #ebedf2;
		padding: 0px 40px;
	}

	.containerAssetsMiddle {
		margin: 32px 0px;
	}

	h3 {
		align-items: center;
		font-weight: 600 !important;
		font-size: 16px !important;
		line-height: 24px !important;
		color: #02151e !important;
	}

	.titleAssets {
		font-family: "Poppins";
		font-size: 14px;
		line-height: 20px;
		color: #596165;
	}

	.btn-orange {
		display: block;
		width: 100%;
		background-color: #f04732 !important;
		font-family: "Poppins";
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		padding: 14px 0px;
		color: #ffffff;
		height: 48px !important;
	}
}

.subtitleStatus {
	padding: 4px 16px;
	background-color: #ebedf2;
	color: #02151e;
	border-radius: 4px;
	margin-left: 16px;
}

.titleModalLote {

	.titulo {
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #02151e;
	}

	.statusLei {
		padding: 4px 16px;
		background-color: #ebedf2;
		border-radius: 4px;
		margin-left: 16px;
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		color: #02151e;
	}
}

.cardGestaoLote {
	background-color: #fff;
	padding: 30px;
	border: #fff solid 1px;
	box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 10%);
	border-radius: 4px;
	height: 400px;

	.titulo {
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #02151e;
	}

	.statusLei {
		padding: 4px 16px;
		background-color: #ebedf2;
		border-radius: 4px;
		margin-left: 16px;
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		color: #02151e;
	}

	&:hover {
		border: #ff6200 solid 1px;
		transition: 0.4s linear;
		cursor: pointer;
	}

	.rc-steps-item-finish .rc-steps-item-tail:after {
		background-color: #ff6200;
		position: relative;
		right: 3px;
	}

	.botoesCrudCardLeilao {
		color: #ff4400;
		border: 0;
		opacity: 0.8;
		background-color: #ffffff;

		&:hover {
			opacity: 0.4;
			transition: 0.2s linear;
			cursor: pointer;
		}
	}

	.crudButtonsContainer {
		display: flex;
		justify-content: flex-end;
	}
}


.cardLeilao {
	background-color: #fff;
	padding: 30px;
	border: #fff solid 1px;
	box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 10%);
	border-radius: 4px;

	.titulo {
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #02151e;
	}

	.statusLei {
		padding: 4px 16px;
		background-color: #ebedf2;
		border-radius: 4px;
		margin-left: 16px;
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		color: #02151e;
	}

	&:hover {
		border: #ff6200 solid 1px;
		transition: 0.4s linear;
		cursor: pointer;
	}

	.rc-steps-item-finish .rc-steps-item-tail:after {
		background-color: #ff6200;
		position: relative;
		right: 3px;
	}

	.botoesCrudCardLeilao {
		color: #ff4400;
		border: 0;
		opacity: 0.8;
		background-color: #ffffff;

		&:hover {
			opacity: 0.4;
			transition: 0.2s linear;
			cursor: pointer;
		}
	}

	.crudButtonsContainer {
		display: flex;
		justify-content: flex-end;
	}
}

.btn-success-orange {
	color: #fff;
	background-color: #ff2500;
	border-color: none;
	height: 46px;
	padding: 0px 20px;

	&:hover {
		background-color: #ff2600d9;
		color: #fff;
	}
}

.btn-remove-styled {
	border-color: #dadada !important;
	background-color: #dadada !important;
	color: #003042 !important;
	font-family: "Roboto";
	font-weight: bold;
	font-size: 12px;
	height: 46px;
	padding: 0px 20px;

	&:hover {
		background-color: #ff2600d9;
		color: #fff;
	}
}

.kt-menu__link-icon {
	color: #fff !important;
}
.kt-menu__link-icon-log{
	color: #ff2500 !important;
}

a.btn.btn-primary.btn-bold {
	background-color: #ff2500 !important;
	border: none !important;
}

.btn-table-action {
	border-color: #dadada !important;
	background-color: #dadada !important;
	height: 34px !important;
	padding: 5px 0;

	i {
		color: #003042 !important;
	}

	&:hover {
		background-color: #949090 !important;
	}

	&.btnDanger {
		background-color: #ff2600d9 !important;

		i {
			color: #fff !important;
		}

		&:hover {
			background-color: #c7270ad9 !important;
		}
	}
}

.cardGestaoLote {
	.verticalLine {
		border-left: #f1f1f179 solid 1px;
	}

	.container-rota {
		.bar-rota {
			width: 0;
			background-color: none;
			border: 1px dashed #ff2601;
		}
	}

	.label-origem-destino,
	.value-origem-destino {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #02151e;
	}

	.label-data {
		font-family: "Poppins";
		font-size: 12px;
		line-height: 20px;
		color: #596165;
	}

	.assets-data {
		margin-top: 24px;
		flex-direction: column;
		display: flex;
		align-items: start;
	}

	.assets {
		padding: 0px;

		h5 {
			font-family: "Poppins";
			font-size: 12px;
			line-height: 20px;
			color: #596165;
		}

		p {
			font-family: "Poppins";
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			color: #02151e;
		}
	}
}

.cardLeilao {
	.verticalLine {
		border-left: #f1f1f179 solid 1px;
	}

	.container-rota {
		.bar-rota {
			width: 0;
			background-color: none;
			border: 1px dashed #ff2601;
		}
	}

	.label-origem-destino,
	.value-origem-destino {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #02151e;
	}

	.label-data {
		font-family: "Poppins";
		font-size: 12px;
		line-height: 20px;
		color: #596165;
	}

	.assets-data {
		margin-top: 24px;
		flex-direction: column;
		display: flex;
		align-items: start;
	}

	.assets {
		padding: 0px;

		h5 {
			font-family: "Poppins";
			font-size: 12px;
			line-height: 20px;
			color: #596165;
		}

		p {
			font-family: "Poppins";
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			color: #02151e;
		}
	}
}

.countDownHeaderLeilao {
	p {
		font-family: "Poppins";
		font-size: 16px;
		line-height: 24px;
		color: #f04732;
		padding-top: 15px !important;
		padding-bottom: 15px !important;
		margin-bottom: 0;
	}
}

.showLeilao {
	.assetsRota {
		margin-top: 40px;
		height: 160px;
	}

	.label-origem-destino,
	.value-origem-destino {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #02151e;
	}

	.assetsToneladasAprov {
		font-family: "Poppins";
		font-size: 14px;
		line-height: 20px;
		color: #596165;
		margin-right: 10px;
	}

	.borderLeilao {
		.assetsLeilaoShow {
			margin-top: 48px;
			font-family: "Poppins";
			font-size: 14px;
			line-height: 20px;
			color: #596165;

			&:first-of-type {
				margin-top: 40px !important;
			}

			&:last-of-type {
				margin-bottom: 48px;
			}

			p {
				font-family: "Poppins";
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				color: #02151e;
				margin-top: 4px;
			}
		}
	}

	.assetsData {
		margin-top: 48px;

		p {
			margin-bottom: 8px;
			font-family: "Poppins";
			font-size: 14px;
			line-height: 20px;
			color: #596165;
		}
	}
}

.card {
	border-radius: 4px;
	margin-bottom: 20px;
	overflow: hidden;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
	transition: height 0.3s;
	width: 31.6%;
	margin: 11px;

  }
  .card-gestao {
	background-color: #ffffff;
	padding: 15px;
	border-radius: 10px;
	padding: 20px 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 260px;
}
  
  
  .card-header {
	background-color: #f0f0f0;
	padding: 10px;
  }
  
  .card-content {
	padding: 10px;
	
  }

  
  .card-footer {
	text-align: center;
	padding: 10px;
  }
  
  .card-footer button {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .card-footer button:hover {
	background-color: #0056b3;
  }

.tokenTypeAsset {
	font-size: 14px;
	line-height: 20px;
	color: #596165;
}

.boxLogin {
	margin-top: 16%;
}

.overflow-dots {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.comprar {
	.MuiCheckbox-colorPrimary {
		padding: 0px !important;
	}
}

.valorTotalPagamento {
	background-color: #ff2500;
	width: 300px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	padding: 20px;
	margin-top: 20px;

	p {
		color: #fff;
		margin-bottom: 0px;
		font-size: 22px;
		font-weight: 700;
	}
}

.link {
	cursor: pointer;
}

.container-edit-peso {
	margin-left: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-edit-peso input {
	width: 70px;
	background: transparent;
	border: none;
	border-bottom: 1px solid #deece8;
}

.container-edit-peso i {
	cursor: pointer;
	margin-left: 8px;
}

@media (max-width: 1500px) {
	.boxContainer {
		.box {
			max-width: 100% !important;
		}
	}

	.boxLogin {
		margin-top: 0%;
	}
}

@media screen and (max-width: 1180px) {
	.containerPhoto {
		display: none;
	}

	.loginContainer {
		.formSide {
			padding-left: 10px;
		}
	}

	.registerContainer {
		display: flex;
		justify-content: center;

		.formSide {
			padding: 0 20px;
			margin-left: 0%;
		}
	}
}

@media screen and (max-width: 768px) {
	.verticalLine {
		border-left: none !important;
	}

	.assetsLeilaoShow {
		margin-bottom: 48px;
	}
}

@media screen and (max-width: 550px) {
	.kt-splash-screen {
		img {
			max-width: 250px !important;
		}
	}

	.valorTotalPagamento {
		background-color: #ff2500;
		width: auto;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		padding: 10px;
		margin-top: 20px;

		p {
			color: #fff;
			margin-bottom: 0px;
			font-size: 15px;
			font-weight: 700;
		}
	}

	.selectProfile {
		width: auto !important;
	}

	.stepsNumber {
		display: none !important;
	}

	.boxContainer {
		.box {
			display: flex !important;
			flex-direction: column;
			text-align: center;
			height: auto !important;
			padding: 20px !important;

			.phraseContainer {
				padding-left: 0 !important;
			}

			h3 {
				margin-top: 15px;
			}
		}
	}
}

@media screen and (max-width: 467px) {
	.faturaButton {
		margin-top: 10px;
	}
}
